import React from "react";
import { Link } from "react-router-dom";

const Home = [
  {
    name: "HOME",
    routerPath: "/",
  },
];

const Pricing = [
  {
    name: "LIGHT CLOUD",
    routerPath: "/pricing-eo",
  },
  {
    name: "DEDICATED SERVER",
    routerPath: "/pricing-cs",
  },
   {
    name: "GPU SERVER",
    routerPath: "/pricing-pm",
  },
  {
    name: "ANTI-DDoS",
    routerPath: "/service-v4",
  },
    {
    name: "SD-WAN",
    routerPath: "/service-v1",
  },
];
const AboutUs = [
  {
    name: "INTRO",
    routerPath: "/about-cs",
  },
];

const ContactUS = [
  {
    name: "CONTACT US",
    routerPath: "/contact-cs",
  },
];
const Team = [
  {
    name: "LA DATA CENTER",
    routerPath: "/portfolio-details-v1",
  },
  {
    name: "Team Version 02",
    routerPath: "/team-2",
  },
  {
    name: "Team Version 03",
    routerPath: "/team-3",
  },
  {
    name: "Team Version 04",
    routerPath: "/team-4",
  },
  {
    name: "Team Version 05",
    routerPath: "/team-5",
  },
  {
    name: "Team Version 06",
    routerPath: "/team-6",
  },
  {
    name: "Team Details",
    routerPath: "/team-details-v1",
  },
  {
    name: "Team Details Slider",
    routerPath: "/team-details-v2",
  },
];

const Services = [
  {
    name: "Service Version 01",
    routerPath: "/service-v1",
  },
  {
    name: "Service Version 02",
    routerPath: "/service-v2",
  },
  {
    name: "Reseller",
    routerPath: "/service-v3",
  },
  {
    name: "ANTI-DDoS",
    routerPath: "/service-v4",
  },
  {
    name: "Service Details",
    routerPath: "/service-details",
  },
];
const Miscellaneous = [
  {
    name: "TERMS CONDITIONS",
    routerPath: "/terms-conditions",
  },
  {
    name: "Login",
    routerPath: "/login",
  },
  {
    name: "Signup",
    routerPath: "/signup",
  },
  {
    name: "404",
    routerPath: "/404",
  },
];
const Portfolio = [
  {
    name: "INTRO",
    routerPath: "/about-cs",
  },
  {
    name: "DATA CENTER",
    routerPath: "/gallery-slider",
  },
  {
    name: "COMPANY NEWS",
    routerPath: "/blog-v6",
  },
  {
    name: "CONTACT US",
    routerPath: "/contact-cs",
  },
];
const Blogs = [
  {
    name: "TERMS OF SERVICE",
    routerPath: "/terms-conditions",
  },
  {
    name: "PRIVACY POLICY",
    routerPath: "/terms-conditions",
  },
];


const MegaMenu = () => {
  return (
    <ul className="navbar-nav">
      <li className="nav-item">
        <a className="nav-link" href="/">
          HOME
        </a>
        <div className="menu">
          <ul className="mega-menu d-flex ">
            {Home.map((val, i) => (
              <li key={i}>
                <Link to={val.routerPath} className={val.clasName}>
                  <span className="font-rubik">{val.title}</span>
                  <div className={val.inenerTextWrapClass}>
                    <div className="font-rubik">{val.inenerText}</div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          PRODUCT
        </a>
        <ul className="dropdown-menu">
          {Pricing.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
       ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          PARTNERSHIP
        </a>
        <ul className="dropdown-menu">
          <li className="dropdown-submenu dropdown">
          </li>

          <li>
            <Link to="/service-v3" className="dropdown-item">
              RESELLER
            </Link>
          </li>
          <li>
            <Link to="/product-customer-support" className="dropdown-item">
              AFFILIATE
            </Link>
          </li>
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          ABOUT US
        </a>
        <ul className="dropdown-menu">
          {Portfolio.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      <li className="nav-item dropdown">
        <a className="nav-link dropdown-toggle" href="#" data-toggle="dropdown">
          LEGAL
        </a>
        <ul className="dropdown-menu">
          {Blogs.map((val, i) => (
            <li key={i}>
              <Link to={val.routerPath} className="dropdown-item">
                {val.name}
              </Link>
            </li>
          ))}
        </ul>
        {/* /.dropdown-menu */}
      </li>
      {/* End li */}

      
    </ul>
  );
  // End navbar nav mega menu main
};

export default MegaMenu;
