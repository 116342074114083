import React from "react";

const featureContent = [
  {
    icon: "186",
    title: "Wide Applicability",
    subTitle: "Based on IP-based three-layer defense, the defense method is not constrained by the customer's business mode.",
    dealyAnimation: "0",
  },
  {
    icon: "187",
    title: "Comprehensive Defense",
    subTitle: "Users do not need to consider the attack source.",
    dealyAnimation: "100",
  },
  {
    icon: "188",
    title: "Flexible Billing",
    subTitle:
      "Customers can upgrade protection levels as needed.",
    dealyAnimation: "200",
  },
];

const FancyFeatureTwentyEightV2 = () => {
  return (
    <div className="row justify-content-between">
      {featureContent.map((val, i) => (
        <div
          className="col-md-4 aos-init aos-animate"
          data-aos="fade-up"
          data-aos-delay={val.dealyAnimation}
          key={i}
        >
          <div className="block-style-twentyEight text-center mt-40">
            <div className="icon d-flex justify-content-center align-items-end">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4 className="font-gordita">{val.title}</h4>
            <p>{val.subTitle}</p>
          </div>{" "}
          {/* <!-- /.block-style-twentyEight --> */}
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureTwentyEightV2;
