import React from "react";

const PricingContent = [
  {
    packName: "16 Cores VPS",
    price: "575",
    packageFor: "Suitable for individual developers or small teams",
    icon: "41",
    preFeatures: [
      "Memory: 32GB",
      "Storage: 250GB SSD",
      "GPU: 1*NVIDIA RTX 4090",
    ],
  },
  {
    packName: "32 Cores VPS",
    price: "1151",
    packageFor: "Suitable for teams or enterprises",
    icon: "41",
    preFeatures: [
      "Memory: 64GB",
      "Storage: 500GB SSD",
      "GPU: 2*NVIDIA RTX 4090",
    ],
  },
  {
    packName: "48 Cores VPS",
    price: "2303",
    packageFor: "Suitable for research institutions or enterprises",
    icon: "41",
    preFeatures: [
      "Memory: 96GB",
      "Storage: 1000GB SSD",
      "GPU: 4*NVIDIA RTX 4090",
    ],
  },
];

const PricingYearly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price font-rubik">
              ${val.price}.<sup>99</sup>
            </div>
            <div className="pack-rec font-rubik">{val.packageFor}</div>
            <img
              src={`images/icon/${val.icon}.svg`}
              alt="icon"
              className="icon"
            />
            <div className="bill-cycle">Billed per agent</div>
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <a href="#" className="theme-btn-four">
              Choose Plan
            </a>
            <div className="trial-text font-rubik">
              Experience unparalleled performance from today
            </div>
          </div>
          {/*  /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingYearly;
