import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import CallToActionFour from "../../../../components/call-to-action/CallToActionFour";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import FaqClassic from "../../../../components/faq/FaqClassicTwo";
import CounterFour from "../../../../components/counter/CounterFour";
import Pricing from "../../../../components/pricing/pricing-two/Pricing";

const ProjectManagement = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          {" "}
          Dedicated Server || Light Cloud Easy Computinge
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
				Pricing Section One
			============================================== */}
      <div className="pricing-section-one mb-150 md-mb-80">
        <div className="fancy-hero-one">
          <div className="container">
            <div className="row">
              <div className="col-xl-10 col-lg-11 m-auto">
                <h2 className="font-rubik">NVIDIA GeForce RTX 4090</h2>
              </div>
              <div className="col-12 m-auto">
                <p className="font-rubik">
                    High Frame Rates · Ray Tracing Technology ·
                    AI-Powered Graphics
                </p>
              </div>
            </div>
          </div>
          <div className="bubble-one"></div>
          <div className="bubble-two"></div>
          <div className="bubble-three"></div>
          <div className="bubble-four"></div>
          <div className="bubble-five"></div>
          <div className="bubble-six"></div>
        </div>
        {/* /.fancy-hero-one */}
        <div className="pricing-table-area">
          <img
            src="images/shape/62.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/63.svg"
            alt="shape"
            className="shapes shape-two"
          />
          <div className="container">
            <div className="tab-content">
              <Pricing />
            </div>
          </div>
          {/* End .container */}
        </div>
      </div>
      {/* /.pricing-section-one */}

      {/* 	=============================================
				Fancy Text Block Fifteen
			============================================== */}
      <div className="fancy-text-block-fifteen">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="bg-wrapper">
            <div className="row">
              <div
                className="col-lg-6 order-lg-last"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="text-wrapper md-pb-40">
                  <img src="images/icon/42.svg" alt="iocn" className="icon" />
                  <div className="more-text">
                    NVIDIA <span>RTX 4090</span>
                  </div>
                  <p className="font-rubik">
                    Quickly process and visualize large datasets, uncover hidden insights, and make data-driven decisions with confidence.
                  </p>
                  <h6>
                    Experience unparalleled performance from  <span> today.</span>
                  </h6>
                </div>
                {/*  /.text-wrapper */}
              </div>
              {/* End .col */}

              <div
                className="col-lg-6 order-lg-first"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                <img
                  src="images/media/img_40.png"
                  alt="media"
                  className="main-img"
                />
              </div>
              {/* End .col */}
            </div>
          </div>
          {/* /.bg-wrapper */}

          <div className="contact-banner mt-100 md-mt-60">
            <div className="row justify-content-between align-items-center">
              <div
                className="col-xl-7 col-lg-8"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <p className="font-rubik">
                  Check more info
                </p>
              </div>
              <div
                className="col-xl-4 col-lg-3"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <Link to="/service-v2" className="theme-btn-four ms-auto">
                  GO NOW
                </Link>
              </div>
            </div>
          </div>
          {/* /.contact-banner */}
        </div>
      </div>
      {/*  /.fancy-text-block-fifteen */}

      {/* 

      {/* 
      =============================================
				Footer
		  ============================================== */}
      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProjectManagement;
