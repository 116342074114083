import React from "react";

const AboutFour = () => {
  return (
    <div className="row">
      <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
        <div className="text-wrapper">
          <div className="title-style-two">
            <p>Technical Support</p>
            <h2>Is there any support available for Affialiate Program?</h2>
          </div>
          {/* /.title-style-two */}
          <p className="sub-text">
            We offer 24/7 technical support services. If users have any questions, they are welcome to send a ticket for inquiries.
          </p>
          <p className="sub-text">
            If you are a personal website owner/blogger, please contact us to get early details of promotional activities.
          </p>
          <div className="name">
            Join Us <span>NOW</span>
          </div>
        </div>
      </div>
      {/* End .col */}

      <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
        <div className="img-holder md-mt-80">
          <img src="images/media/img_39.png" alt="media" />
          <img
            src="images/shape/60.svg"
            alt="shape"
            className="shapes shape-one"
          />
          <img
            src="images/shape/61.svg"
            alt="shape"
            className="shapes shape-two"
          />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};

export default AboutFour;
