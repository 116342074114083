import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What are the features of a dedicate server?",
    desc: `Complete physical isolation, exclusive use of all resources. Continuous hardware upgrades are possible for higher configurations of physical servers.
Greater stability assurance.
`,
    expand: "a",
  },
  {
    title: "Do you support the Linux system?",
    desc: `Yes, we support the Linux system and we provide Windws System if you need.`,
    expand: "b",
  },
  {
    title: "How to choose the most suitable bandwidth type?",
    desc: `You can check the [Bandwidth Introduction] or test the most suitable route through Looking Glass.`,
    expand: "c",
  },
  {
    title: "How to control my host?",
    desc: `We provide a comprehensive control panel, you can log in to the user platform for operations.`,
    expand: "d",
  },
  {
    title: "Can I upgrade the host at any time?",
    desc: `Cloud servers support upgrades anytime; dedicated servers confirm with our staff for upgrades through the support ticket.`,
    expand: "e",
  },
  {
    title: " What data centers are currently available?",
    desc: `Currently, there are data centers in San Jose, USA, and Taiwan, China.`,
    expand: "f",
  },
  {
    title: "Can I change the billing cycle?",
    desc: `Yes, you can change it in the user platform easily.`,
    expand: "g",
  },
];

const FaqFour = () => {
  return (
    <div
      className="accordion-style-four"
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="faq-wrraper">
        <Accordion preExpanded={["b"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqFour;
