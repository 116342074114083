import React from "react";
import { Link } from "react-router-dom";
  
const featuresContent = [
  {
    bgColor: "#F7EDFF",
    icon: "178",
    title: "High Performance",
    descriptions:
      "The RTX 4090 is built on the Ada Lovelace architecture, featuring significant improvements in CUDA core count and clock speeds, which provide substantial computational power for AI inference tasks. It can deliver up to 82.6 teraflops of performance, which is crucial for processing large AI models quickly and efficiently.",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: "179",
    title: "Tensor Cores",
    descriptions:
      "The RTX 4090 includes fourth-generation Tensor Cores, which are specialized processing units designed to accelerate matrix operations, a core component of many AI algorithms. These Tensor Cores improve the throughput and efficiency of deep learning tasks, enabling faster model inference and training times.",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: "180",
    title: "Memory Bandwidth and Capacity",
    descriptions:
      "The RTX 4090 is equipped with 24 GB of GDDR6X memory, offering high bandwidth (up to 1 TB/s) which is essential for handling large datasets and models without significant latency. This large memory capacity allows for the processing of more complex models and larger batches of data simultaneously.",
    dealyAnimation: "",
  },
  {
    bgColor: "#FFEBDB",
    icon: "181",
    title: "DLSS (Deep Learning Super Sampling)",
    descriptions:
      "The RTX 4090 supports NVIDIA’s DLSS technology, which leverages AI to upscale lower-resolution images to higher resolutions in real-time. While traditionally used for gaming, this technology can be adapted for various AI applications, enhancing image and video processing tasks.",
    dealyAnimation: "100",
  },
    {
    bgColor: "#F7EDFF",
    icon: "178",
    title: "CUDA and Software Ecosystem",
    descriptions:
      "The RTX 4090 is fully compatible with NVIDIA’s CUDA platform, which provides a robust environment for developing AI applications. This includes support for popular AI frameworks such as TensorFlow, PyTorch, and MXNet, facilitating seamless integration and deployment of AI models.",
    dealyAnimation: "",
  },
  {
    bgColor: "#D8FFF1",
    icon: "179",
    title: "Energy Efficiency",
    descriptions:
      "Despite its high performance, the RTX 4090 includes architectural improvements that enhance energy efficiency, reducing the power consumption relative to the computational output. This is beneficial for data centers and other deployment environments where energy costs and thermal management are significant concerns.",
    dealyAnimation: "100",
  },
  {
    bgColor: "#E6FBFF",
    icon: "180",
    title: "Scalability",
    descriptions:
      "The RTX 4090 can be deployed in multi-GPU configurations, allowing for scalable performance improvements. This scalability is critical for large-scale AI inference tasks that require substantial computational resources.",
    dealyAnimation: "",
  },
  {
    bgColor: "#FFEBDB",
    icon: "181",
    title: "Precision and Flexibility",
    descriptions:
      "The RTX 4090 supports a range of precision levels, including FP32, FP16, INT8, and INT4. This flexibility allows users to choose the appropriate precision level for their specific inference workloads, balancing performance and accuracy as needed.",
    dealyAnimation: "100",
  },
];

const FancyFeatureThirtyFour = () => {
  return (
    <div className="row">
      {featuresContent.map((item, i) => (
        <div
          className="col-md-6"
          data-aos="fade-up"
          data-aos-delay={item.dealyAnimation}
          key={i}
        >
          <div className="block-style-thirtyTwo d-flex">
            <div
              className="icon d-flex align-items-center justify-content-center"
              style={{ background: item.bgColor }}
            >
              <img src={`images/icon/${item.icon}.svg`} alt="icon" />
            </div>
            <div className="text">
              <h4>{item.title}</h4>
              <p>{item.descriptions}</p>
              <Link to="/pricing-pm" className="tran3s">
                <img src="images/icon/182.svg" alt="icon" />
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FancyFeatureThirtyFour;
