import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import ScrollspyNav from "react-scrollspy-nav";

const TermsConditions = () => {
  return (
    <div className="doc-container main-page-wrapper">
      <Helmet>
        <title>
          Terms & Conditions || Lightlayer - Worldwide ISP
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =====================================================
				Terms and Condition
			===================================================== */}

      <div className="terms_and_policy">
        <div className="container">
          <ScrollspyNav
            scrollTargetIds={["opt1", "opt2", "opt3", "opt4", "opt5", "opt6"]}
            activeNavClass="active"
            offset={170}
            scrollDuration="300"
          >
            <div className="row align-items-start">
              <div className="col-lg-4 sidenav-sticky">
                <ul className="nav nav-tabs">
                  <li className="nav-item">
                    <a className="nav-link active" href="#opt1">
                      1. Terms of Service
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="#opt2">
                      2. Privacy Policy
                    </a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-8">
                {/* Tab panes */}
                <div className="tab-content ">
                  <div id="opt1">
                    <h2 className="font-gilroy-bold">Terms of Service</h2>
                    <div className="update-date">LAST UPDATED: 1/2/2024, Lightlayer and Zillion Network Joint Declaration</div>
                    <h3>Service Agreement</h3>
                    <p>
                      By using the services or products (hereinafter referred to as "Services", "Products", including servers, VPS) at lightlayer.net, you agree to the terms and conditions contained in this Service Level Agreement (hereinafter referred to as the "Agreement"). This Agreement is between you, your organization (if you are entering into this Agreement on behalf of an organization), collectively referred to herein as "User", "you" or "your" (and its appropriate derivatives) and lightlayer.net (referred to herein as Lightlayer, "we", "our" and "us").<br />
<br />
We value your privacy and strive to protect it using commercially acceptable means. Please review the Privacy Policy for more details.<br />
<br />
Reports of activities that violate this Agreement can be sent to abuse@lightlayer.net.<br />
<br />
Lightlayer agrees to provide your Service to you so long as you are in full compliance with our Terms of Service. Lightlayer reserves the right, in its sole discretion, to refuse to provide service to any potential User and/or to refuse to renew service to any existing User. If you have purchased our products, it is deemed that you understand and agree to the contents of this Agreement. If any dispute arises due to non-compliance with this Agreement during use, Lightlayer shall not bear any responsibility beyond the terms of this Agreement, and the User shall bear all responsibility. If you have any questions about Lightlayer's Terms of Service, you can consult us by submitting a ticket, which will help protect your consumer rights on the Lightlayer service platform.<br />
<br />
 <h3>Lightlayer Service Content</h3>
 <p>
After the User has completed the payment normally, Lightlayer will provide the User with the product content described in the order according to the User's order requirements and maintain the normal operation of the product and provide stable services to the User.
</p>
<h3>User Obligations</h3>
 <p>
Email is an important channel for our company to contact users. All notifications from our company, including but not limited to service activation, suspension, termination, recycling, network cutover, and attack alerts, are first sent to the user through this channel. Therefore, the user is obligated to maintain the latest email information in our company's system.
</p>
<br />
When using Lightlayer services for any activities, users should comply with the laws of the local data center and should be subject to the supervision and permits of relevant departments in the country/region where the product is located. It is forbidden to use our company's products to infringe on the legitimate rights of others (including intellectual property rights). The regulations that need to be complied with include but are not limited to the following:<br />
a. Computer Crimes Ordinance<br />
b. Electronic Transactions Ordinance<br />
c. Unsolicited Electronic Messages Ordinance<br />
d. Personal Data (Privacy) Ordinance<br />
e. Copyright Ordinance<br />
<br />
Please review and check by yourself whether you are in violation of the above laws.<br />
Lightlayer prohibits users from using our resources for the following purposes:<br />
a. Sensitive political purposes;<br />
b. Any hacking, placing of Trojan horses, viruses, and other attacks;<br />
c. Posting fraudulent phishing, pornography, gambling, violence, threats, incitement of hatred, imitation of brands, and other illegal content;<br />
d. Posting any unauthorized intellectual property rights, including but not limited to books, music, software, and videos;<br />
e. Sending spam;<br />
f. Renting or operating VPNs to carry out cross-border telecommunications business operations in violation of regulations;<br />
g. Any behavior that may cause Lightlayer to suffer network retaliation or affect other users;<br />
h. Any other content that violates local national laws and involves any criminal acts.<br />
Once discovered, our company has the right to immediately terminate the service and clear all data, including server and account data.<br />

 <h3>Service Duration </h3>
 <p>
Lightlayer will provide services to users from the date of payment completion until the service is taken down, cancelled due to non-payment, not renewed, or forgotten to be renewed.<br />
</p>
 <h3>Technical Services </h3>
 <p>
Lightlayer offers one free system reinstallation for each server per month. If this limit is exceeded, Lightlayer will charge a reinstallation fee of 30 yuan each time.<br />
Users can autonomously choose from Linux systems such as Debian, CentOS, Ubuntu, and Windows series including Windows 2003, Windows 2008, etc., through the product's system options (activation required by the user).
</p>
 <h3>Disclaimer </h3>
 <p>
Lightlayer shall not be liable in the following circumstances:<br />
a. Product inaccessibility (usage) or data loss due to user's non-payment, non-renewal, or forgotten renewal.<br />
b. Product inaccessibility (usage) or data loss due to user's operation or other inappropriate actions.<br />
c. Inaccessibility or data loss due to user's website content or usage behavior violating the obligations specified in these terms.<br />
d. Inaccessibility or data loss due to major social abnormal events such as war, armed conflict, strike, riot, or insurgency.<br />
e. Inaccessibility or data loss due to natural disasters such as earthquakes, tsunamis, typhoons, waves, floods, locusts, storms, hail, sandstorms, volcanic eruptions, landslides, avalanches, mudslides.<br />
f. Inaccessibility or widespread internet transmission failures beyond our control, such as disasters, human destruction, or telecom infrastructure failures caused by war; impacts due to large-scale network failures caused by hacker attacks.<br />
g. Short-term service interruptions during product configuration, upgrade, or maintenance.<br />
h. Service interruptions caused by network cutover by the operator or power cutover in the data center.
 </p>
Lightlayer reserves the right to take the following actions upon receiving complaints or orders from the government and courts:<br />
a. Shut down the product or IP address used by the user upon government order.<br />
b. Follow instructions to provide relevant data or evidence, or even shut down user services upon receiving documents from courts or investigative agencies.<br />
c. Shut down the user's product and preserve evidence upon receiving complaints from third parties or lawyer letters and evidence submitted by appointed lawyers.<br />
d. Stop the abuse of IP addresses upon complaints received by Lightlayer's network security department from third parties or IP management departments.<br />
e. Terminate the user's product or network connection upon proactive detection of illegal attack activities by Lightlayer's network security department.<br />
f. Cooperate as necessary, including but not limited to submitting evidence or disconnecting the user, when Lightlayer's upstream suppliers face complaints or investigations due to the user's content or actions.<br />
<br />
If the user violates the above terms, Lightlayer will stop its service operations. No refunds will be given for any payments, and Lightlayer reserves the right to delete services, suspend services, and delete user accounts.<br />
<br />
Lightlayer is not obligated to review whether users have the necessary compliance permits. Any economic, political, or legal disputes arising from the user's business activities are the user's responsibility to resolve and bear. Users must agree that in such events, Lightlayer bears no responsibility or obligation to the user or third parties.
<br />
<h3>Service</h3>
We offer different types of bandwidth. Users should choose according to their needs:<br />
Mainland Premium Bandwidth includes the highest quality internet lines directly connected to China's three major operators and the data center, as well as routes for international and local data center access, maximizing the availability and quality of access for global users.<br />
<br />
Standard Bandwidth includes ordinary lines connecting China's three major operators with the data center, along with routes for international and local data center access. Omni-directional bandwidth provides users with direct routes to the data center from around the world, including mainland China. However, the quality of access (including latency and packet loss rate) is subject to line congestion and is not guaranteed during peak usage times.<br />
<br />
International Bandwidth covers local and international routing but does not include direct routes connecting China's three major operators with Hong Kong. Therefore, it only offers direct routes for international and local data center access. Routes for mainland China may be detoured through a third location, so latency and packet loss rate are not guaranteed.<br />
<br />
In case of problems with the data center and lines, including access delays, packet loss, or inaccessibility caused by domestic and international network operators, Lightlayer will assist in providing analysis evidence but will not compensate or refund. However, we will fully cooperate with network operators to resolve issues as quickly as possible.<br />
<br />
Lightlayer offers server rental services. We reserve the right to reclaim resources due to adjustments by upstream operators or our own business adjustments. Resources include but are not limited to IP, bandwidth, and server hardware. We will strive to negotiate and communicate with users, providing corresponding replacement and transition solutions.<br />
If a user's IP is blocked or flagged due to their business activities, we will not provide IP replacement services.<br />
<br />
After service delivery, the user is fully responsible for the system's security. We are not responsible for data loss due to hacking, user operations, account theft, or other issues.<br />
<br />
<h3>Network and IP Abuse (Abuse)</h3>
<p>
a. Lightlayer strictly prohibits any form of network and IP abuse, including but not limited to Denial of Service attacks, phishing, port scanning, SYN floods, etc. In addition to detections by Lightlayer's network security department, Lightlayer will also respond according to the "Notification on the Punishment of Abuse Complaints for IP Misuse" based on Abuse emails sent by upstream operators. This includes but is not limited to:<br />
b. Attempting to probe, scan, or test system or network vulnerabilities;<br />
c. Initiating attacks, including but not limited to SYN floods, intentional system overloading, and broadcast attacks;<br />
d. Password cracking/hijacking, security vulnerability scanning, and port scanning;<br />
e. Unreasonable use of the network, causing interference to other users;<br />
f. Infringement, including unauthorized use of books, music, software, videos, and other intellectual property;<br />
g. Phishing, fraud, and similar activities;<br />
h. Sending spam;<br />
i. Distributing obscene materials or child pornography;<br />
j. Engaging in or facilitating illegal gambling;<br />
k. Promoting pyramid schemes and other illegal plans.
</p>
<h3>Data Security</h3>
<p>
As computer hard drives and storage devices are consumables, even under hardware RAID10 environments, 100% fault-free operation cannot be guaranteed. Therefore, users are responsible for their own data security, and Lightlayer strongly recommends regular data backups;<br />
Lightlayer is not responsible for compensation for data loss due to hardware failure or user abuse, but will fully assist users in data recovery and problem resolution. If user data loss is caused by our staff's operational errors or other reasons, we will actively negotiate with the user, recover data, and provide satisfactory solutions depending on the specific situation.
</p>
<h3>Payment and Refund Policy</h3>
<p>
Users are responsible for tracking their account bills and renewing services. When services expire, users face the risk of service suspension, cancellation, and data loss;<br />
Once a service expires, the user's account will be suspended or deleted, and Lightlayer has the right to delete service data without notifying the user, for which we bear no responsibility;<br />
Lightlayer does not support refunds for reasons not attributable to the company, including but not limited to regular priced and promotional products.
</p>
<h3>Discounts and Price Adjustments</h3>
<p>
Lightlayer reserves all rights to offer discounts and adjust prices. Price changes apply only to new orders, not to existing orders and ongoing services. Lightlayer reserves the right to modify the product system and prices at any time without notifying users.
</p>
<h3>Liability for Breach</h3>
<p>
If a user violates the service terms, Lightlayer will directly delete all the user's data without providing backups or compensation.<br />
If a user violates the service terms and causes serious consequences or adverse impacts, Lightlayer will not only delete the user's data without providing backups or compensation but will also actively cooperate with relevant departments, providing necessary information as evidence.<br />
The referral program is for natural persons recommending other natural persons. If self-referral is detected, we reserve the right to clear your referral information.
</p>
<h3>Account and Others</h3>
<p>
Users are responsible for securing their account and regularly changing their password. Lightlayer is not liable for losses caused by account password leaks.<br />
Account balances can be used for purchases of all Lightlayer products, but cannot be withdrawn;<br />
Our product prices are exclusive of taxes.<br />
Users are responsible for ensuring their settlement information with Lightlayer is up-to-date and accurate. Providing false information, including fraudulent use of credit card numbers, will result in service suspension and may incur civil or criminal liabilities. Lightlayer reserves the right to determine, at its discretion, whether the use of its services violates the service terms, including but not limited to the content mentioned above that violates the service terms.
</p>
<h3>Changes to Service Terms</h3>
<p>
Lightlayer reserves the right to change the service terms at any time. Modifications to the service terms become effective immediately upon posting. Continued use of Lightlayer's network and services after any such modifications will constitute acceptance of these changes. Users are advised to regularly review the latest version of the service terms.
</p>
                    </p>
                  
                  </div>
                  <div id="opt2">
                    <h2 className="font-gilroy-bold">Privacy Policy</h2>
                    <div className="update-date">LAST UPDATED: 1/2/2024, Lightlayer and Zillion Network Joint Declaration</div>
                    <h3>Explanation</h3>
                    <p>
                      a. This privacy policy applies to all users of Lightlayer.<br />
b. By registering as a user of Lightlayer, you agree to comply with this privacy policy.

                    </p>
                    <h3>Types of Information Collected</h3>
                    <p>
We may collect user information in various ways, including but not limited to situations such as when visitors access our website, users register an account, place orders, submit service tickets, and use our services.<br />
<br />
a. Personal Information: Information used to identify your identity or to contact you, including but not limited to your name, email, phone number, address, and third-party payment gateway information. This information is collected when you register as a user. For verification purposes, we may request information that proves your identity.<br />
b. Non-Personal Information: All visitor information used for statistical or other analytical purposes, including but not limited to IP addresses, browser information, computer information, screen information, etc.<br />
                    </p>

 <h3>Types of Information Collected</h3>
                    <p>
<strong>Browser Cookies</strong><br />
Our website uses small text files (Cookies) to enhance user experience. Cookies are stored on users' hard drives for recording and tracking information. Users can change their browser settings to refuse Cookies.
                    </p>

 <h3>How We Use Collected Information</h3>
 <p>
We collect and use personal information for the following purposes:<br />
a. To provide users with ordered services.<br />
b. To process payments.<br />
c. To contact users based on their services or respond to their inquiries.<br />
d. IP localization and fraud prevention.<br />
e. To send emails related to the user's services and products.<br />
f. We use non-personal information for statistical and analytical purposes to better understand visitor behavior and improve website design.<br />
                    </p>
                    
 <h3>How We Protect Information</h3>
  <p>
We implement appropriate data collection, storage, processing measures, and security strategies to prevent unauthorized access, alteration, disclosure, or destruction of users' personal information, usernames, passwords, transaction information, and other data stored on our site. Sensitive and private data exchanges between users and the site are conducted over a secure SSL-encrypted communication channel.<br />

 <h3>Third-Party Data Disclosure</h3>
   <p>
We may disclose non-personal information to third parties for statistical and analytical purposes. Except for the following cases, we will not disclose users' personal information to third parties:<br />
a. Based on legal requirements, court orders, or other legal processes.<br />
b. In potential business mergers, acquisitions, debt financing, or similar transactions, or in events such as bankruptcy, as part of transferring company assets to one or more third parties.<br />
c. Third parties who provide services on our behalf (such as payment gateways).<br />
d. If users visit and register through a promotional link, their names may be disclosed to the promoters to inform them that the users registered through their links.<br />
</p>

<h3>Changes to the Privacy Policy</h3>
 <p>
Lightlayer reserves the right to change this privacy policy at any time. When changes are made, we will update the last updated date at the end of the document. We encourage users to regularly review this page for the latest policy information. You understand and agree that it is your responsibility to review the latest privacy policy regularly.
</p>


                    </p>
                   
                  </div>
                </div>
                {/*  /.tab-content */}
              </div>
            </div>
          </ScrollspyNav>
        </div>
      </div>

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default TermsConditions;
