import React from "react";

const projectInfo = [
  { title: "LOCATION", meta: "Los Angeles" },
  { title: "ADDRESS", meta: "288583 Square Foot" },
  { title: "GRADE", meta: "Tier III" },
  { title: "CABINET", meta: "45U & 4-6kW" },
];

const ProjectInfo = () => {
  return (
    <>
      {projectInfo.map((val, i) => (
        <li key={i}>
          <strong>{val.title}</strong>
          <span>{val.meta}</span>
        </li>
      ))}
    </>
  );
};

export default ProjectInfo;
