import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What are the features of a dedicate server?",
    desc: `Dedicated servers are complete physical isolation and exclusive use of all resources. Dedicated servers are continuous hardware upgrades that are possible for higher configurations of physical servers and with greater stability assurance.`,
  },
  {
    title: "What is the maximum of CPU cores for dedicate server?",
    desc: `We offers dedicated servers with up to 128 cores. You can exclusively use all the resources of the dedicated server, whether it's memory, hard disk, or bandwidth, and can deploy them according to your needs.`,
  },
  {
    title: "What is the maximum number of cores for a dedicate server?",
    desc: `We offers dedicated servers with up to 128 cores. You can exclusively use all the resources of the dedicated server, whether it's memory, hard disk, or bandwidth, and can deploy them according to your needs.`,
  },
  {
    title: "Does the dedicate server support upgrades?",
    desc: `Yes, dedicated servers support upgrades. If you have requirements for higher configurations, please feel free to contact us at any time.`,
  },
  {
    title: "What is the maximum bandwidth can upgrade?",
    desc: `Dedicated servers can be upgraded to a network bandwidth of more than 10Gbps.`,
  },
  {
    title: "Can multiple dedicate servers be networked internally?",
    desc: `Yes, multiple physical servers support internal networking.`,
  },
  {
    title: "What payment methods are supported?",
    desc: `We offer payment methods such as Paypal and Alipay.`,
  },
  {
    title: "Do you provide technical support services?",
    desc: `We provide 24/7 technical support services. If you need technical support, please feel free to contact us through a ticket at any time.`,
  },
  {
    title: "Does it support reseller system?",
    desc: `Yes, we are welcome you to become our reseller, you can check the agent page to confirm whether you meet the requirements.`,
  },
  {
    title: "How many hosts can one person buy?",
    desc: `We have no upper limit on the purchase quantity for one person, but if your quantity is large enough, you are welcome to contact our sales to get a cheaper price.`,
  },
];

const FaqThree = () => {
  return (
    <div className="accordion-style-three">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem
              className="card"
              key={i}
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqThree;
