import React from "react";

const PricingContent = [
  {
    packName: "AMD",
    price: "92300",
    billCycle: "per year",
    preFeatures: [
      "2*AMD EYPC 7742",
      "64 CORES",      
      "256 RAM",
      "2*240G SSD", 
      "2*4T NVME",      
      "5 IPs",
      "Standard Network",
      "10 Gbps Speed port",
      "ULIMITED DATA",
      "UPGRADEALBE",
    ],
  },
  {
    packName: "INTEL",
    price: "86300",
    billCycle: "per year",
    preFeatures: [
      "E5-2696V4 DUAL",
      "44 CORES",
      "128G RAM",
      "2T SSD", 
      "5 IPs",
      "Standard Network",
      "10 Gbps Speed port",
      "ULIMITED DATA",
      "UPGRADEALBE",
    ],
  },
  {
    packName: "AMD",
    price: "9000",
    billCycle: "per year",
    preFeatures: [
     "2*AMD EYPC 7502",
     "64 CORES",
      "256G RAM",
      "2*240G SSD", 
      "2*4T NVME", 
      "5 IPs",
      "Premium Network",
      "100 Mbps Speed port",
      "ULIMITED DATA",
      "UPGRADEALBE",
    ],
  },
  {
    packName: "INTEL",
    price: "1500",
    billCycle: "per year",
    preFeatures: [
      "E5-2660 DUAL",
      "16 COREs",
      "64G RAM",
      "1T SSD", 
      "5 IPs",
      "Premium Network",
      "50 Mbps Speed port",
      "ULIMITED DATA",
      "UPGRADEALBE",
    ],
  },
];

const PricingYearly = () => {
  return (
    <div className="row no-gutters">
      {PricingContent.map((val, i) => (
        <div className="col-lg-3 col-sm-6 pr-bg d-flex" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price">
              <sup>$</sup>
              {val.price}
            </div>
            <div className="bill-cycle">{val.billCycle}</div>
            <a href="#" className="theme-btn-three">
              Sign Up
            </a>
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
          </div>
          {/* /.pr-table-wrapper  */}
        </div>
      ))}
    </div>
  );
};

export default PricingYearly;
