import React from "react";
import { Link } from "react-router-dom";

const FooterEight = () => {
  return (
    <div className="row justify-content-end">
      <div className="col-xl-4 col-lg-3 col-12 footer-about-widget">
        <div className="logo">
          <a href="index">
            <img src="images/logo/ll_06.png" alt="" />
          </a>
        </div>
        <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:sales@lightlayer.net">sales@lightlayer.net</a>
          </li>
          <li>
            <a href="te" className="Telegram @lightlayer">
              Telegram @lightlayer
            </a>
          </li>
        </ul>
      </div>
      {/*  /.about-widget */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Links</h5>

        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/service-v3">Reseller</Link>
          </li>
          <li>
            <Link to="/product-customer-support">Affiliate</Link>
          </li>
          <li>
            <Link to="/about-cs">About Us</Link>
          </li>
          <li>
            <Link to="/blog-v2">News</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Legal</h5>
        <ul>
          <li>
            <Link to="/terms-conditions">Terms of service</Link>
          </li>
          <li>
            <Link to="/terms-conditions">Privacy policy</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-xl-2 col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Products</h5>
        <ul>
          <li>
            <Link to="/gallery-slider">Data Center</Link>
          </li>
          <li>
            <Link to="/pricing-eo">Light Cloud</Link>
          </li>
          <li>
            <Link to="/pricing-cs">Dedicated Server</Link>
          </li>
          <li>
            <Link to="/service-v4">Anti-DDoS</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //   /.row
  );
};

export default FooterEight;
