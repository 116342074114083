import React from "react";

const FeatureContent = [
  {
    icon: "35",
    meta: "Diverse Product Portfolio",
    subTitle: ` Lightlayer offers a diverse range of products and services to cater to the varying needs of its customers.`,
    dataDelay: "0",
  },
  {
    icon: "36",
    meta: "Continuous Innovation and Service Optimization",
    subTitle: ` Lightlayer's unwavering dedication to excellence ensures that customers consistently receive the highest quality services and the best possible user experience.`,
    dataDelay: "100",
  },
  {
    icon: "37",
    meta: "Partnering for Success",
    subTitle: `Lightlayer strives to create a mutually beneficial ecosystem that drives innovation, enhances customer satisfaction, and fosters long-term success for all stakeholders.`,
    dataDelay: "300",
  },
];

const FancyFeatureSeven = () => {
  return (
    <>
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-seven">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <div className="feature-info">
              <span>{val.meta}</span>
            </div>
            <p className="font-rubik">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </>
  );
};

export default FancyFeatureSeven;
