import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: " From registration to setup takes only a few minutes.",
    desc: ` Smooth and simple setup, providing a user-friendly platform.`,
    expand: "a",
  },
  {
    title: "Just click to update",
    desc: ` customizable upgrades, instant upgrades`,
    expand: "b",
  },
  {
    title: "Manage your host easily",
    desc: ` Clear and easy-to-use platform for clients`,
    expand: "c",
  },
];

const Faq = () => {
  return (
    <div className="accordion-style-two pe-5">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default Faq;
