import React from "react";
import { Link } from "react-router-dom";
import BlogSidebarForm from "../form/BlogSidebarForm";

const categroies = [
  {
    catName: "PRODUCTS",
    numberOfPOst: "02",
  },
  {
    catName: "SOLUTIONS",
    numberOfPOst: "00",
  },
  {
    catName: "DATA CENTER",
    numberOfPOst: "02",
  },
  {
    catName: "INDUSTRY",
    numberOfPOst: "00",
  },
  {
    catName: "PROMOTION",
    numberOfPOst: "00",
  },
];

const recentNews = [
  {
    title: "Los Angeles Datacenter.",
    date: "02 Feb, 2024",
    routePath: "/blog-details",
  },
  {
    title: "Lightlayer GPU Servers Launch.",
    date: "25 June, 2024",
    routePath: "/blog-details1",
  },
];

const blogKeyword = ["GPU", "LOS ANGELAS", "CLOUD", "SERVER", "IDC", "RTX 4090", "DALLAS"];

const BlogSidebar = () => {
  return (
    <>
      <div className="blog-sidebar-one">
        <div className="sidebar-search-form mb-85 sm-mb-60">
          <BlogSidebarForm />
        </div>
        {/* /.sidebar-search-form */}

        <div className="sidebar-categories mb-85 sm-mb-60">
          <h4 className="sidebar-title">Categories</h4>
          <ul>
            {categroies.map((cat, i) => (
              <li key={i}>
                <Link to="/blog-details">
                  {cat.catName} <span>({cat.numberOfPOst})</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* /.sidebar-categories */}

        <div className="sidebar-recent-news mb-85 sm-mb-60">
          <h4 className="sidebar-title">Recent News</h4>
          <ul>
            {recentNews.map((news, i) => (
              <li key={i}>
                <Link to={news.routePath}>
                  <span className="title">{news.title}</span>
                  <span className="date">{news.date}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        {/* /.sidebar-recent-news */}

        <div className="sidebar-keyword">
          <h4 className="sidebar-title">Keywords</h4>
          <ul className="cleafix">
            {blogKeyword.map((val, i) => (
              <li key={i}>
                <Link to="/blog-details"> {val}</Link>
              </li>
            ))}
          </ul>
        </div>
        {/* /.sidebar-keyword */}
      </div>
      {/* /.blog-sidebar-one  */}
    </>
  );
};

export default BlogSidebar;
