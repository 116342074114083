import React from "react";
import { Link } from "react-router-dom";

const featuresContent = [
  {
    icon: "181",
    bgColor: "#F7EDFF",
    title: "Priority Service",
    subTitle: `Resellers will receive higher priority in ticket handling and more efficient after-sales service.`,
    dealyAnimation: "100",
  },
  {
    icon: "183",
    bgColor: "#FFF8E0",
    title: "Comprehensive Reseller System",
    subTitle: `We have a well-established reseller system to support your business development.`,
    dealyAnimation: "200",
  },
  {
    icon: "132",
    bgColor: "#FFEBDB",
    title: "Reseller Discount Program",
    subTitle: `Offering higher profits at lower prices.`,
    dealyAnimation: "0",
  },
  {
    icon: "133",
    bgColor: "#E0F8F8",
    title: "Comprehensive After-Sales Service",
    subTitle: `We offer 24/7 technical support services.`,
    dealyAnimation: "100",
  },
  {
    icon: "184",
    bgColor: "#ECF1FF",
    title: "Market Support",
    subTitle: `We will provide more support to resellers through market channels.`,
    dealyAnimation: "200",
  },
];

const FancyFeatureThirtyFive = () => {
  return (
    <>
      {featuresContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dealyAnimation}
          key={i}
        >
          <Link to="/service-details" className="block-style-twentyTwo">
            <div
              style={{ background: val.bgColor }}
              className="icon d-flex align-items-center justify-content-center"
            >
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h4>{val.title}</h4>
            <p>{val.subTitle}</p>
          </Link>
          {/* <!-- /.block-style-twentyTwo --> */}
        </div>
      ))}
    </>
  );
};

export default FancyFeatureThirtyFive;
