import React from "react";

const projectInfo3 = [
  { title: "LOCATION", meta: "Manila" },
  { title: "ADDRESS", meta: "Makati City" },
  { title: "GRADE", meta: "Tier III" },
  { title: "CABINET", meta: "42U & 4KVA" },
];

const ProjectInfo3 = () => {
  return (
    <>
      {projectInfo3.map((val, i) => (
        <li key={i}>
          <strong>{val.title}</strong>
          <span>{val.meta}</span>
        </li>
      ))}
    </>
  );
};

export default ProjectInfo3;
