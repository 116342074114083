import React from "react";

const projectInfo2 = [
  { title: "LOCATION", meta: "Taipei" },
  { title: "ADDRESS", meta: "Yangguang Street, Neihu District" },
  { title: "GRADE", meta: "Tier III" },
  { title: "CABINET", meta: "42U & 4kW" },
];

const ProjectInfo2 = () => {
  return (
    <>
      {projectInfo2.map((val, i) => (
        <li key={i}>
          <strong>{val.title}</strong>
          <span>{val.meta}</span>
        </li>
      ))}
    </>
  );
};

export default ProjectInfo2;
