import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What are the features of a dedicate server?",
    desc: `<br>Complete physical isolation, exclusive use of all resources.</br>
Continuous hardware upgrades are possible for higher configurations of physical servers.
Greater stability assurance.
".`,
    expand: "a",
  },
  {
    title: "Do you support the Linux system?",
    desc: `Yes, we support the Linux system and we provide Windows System if you need.".`,
    expand: "b",
  },
  {
    title: "How to choose the most suitable bandwidth type?",
    desc: `You can check the [Bandwidth Introduction] or test the most suitable route through Looking Glass.".`,
    expand: "c",
  },
  {
    title: "What do you look for in a founding team?",
    desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`,
    expand: "d",
  },
  {
    title: "Do you recommend Pay as you go or Pre pay?",
    desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`,
    expand: "e",
  },
  {
    title: "Can I pass the fees on to my customers?",
    desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`,
    expand: "f",
  },
  {
    title: " How do I get paid for the tickets sold?",
    desc: `It's free to use for your first five ticket sales. Once your sixth ticket purchase comes through we will start charging the standard PAYG rate. If you would like to move to Pre pay then head to "Billing" and "Buy ticket credits".`,
    expand: "g",
  },
];

const FaqClassicThree = () => {
  return (
    <div className="accordion-style-five md-mt-60">
      <div className="faq-wrraper">
        <Accordion allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassicThree;
