import React from "react";
import { Link } from "react-router-dom";

const CallToActionThree = () => {
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <div className="row align-items-center">
      <div className="col-lg-6">
        <div className="title-style-one">
          <h6 className="font-rubik" style={{ color: "#f96f60" }}>
            Want to know more?
          </h6>
          <h2>Contact us.</h2>
        </div>
        {/* /.title-style-one */}
      </div>
      {/* End .col */}

      <div className="col-lg-6">
        <div className="form-wrapper">
          <form onClick={handleSubmit}>
            <input type="text" placeholder="Email address" />
            <button>SIGN UP</button>
          </form>
          {/* End form */}
          <p className="font-rubik">
            Already a member? <a href="https://account.lightlayer.net/">Sign in.</a>
          </p>
        </div>
        {/* /.form-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default CallToActionThree;
