import React from "react";

const PricingContent = [
  {
    packName: "AMD 7542*2",
    price: "4607",
    packageFor: "Suitable for Large-scale data processing and deep learning model training",
    icon: "38",
    preFeatures: [
      "Memory: 1024GB",
      "Storage: 480GB SATA SSD*2 + 3.84TB U.2 SSD*1",
      "GPU: 8*NVIDIA RTX 4090",
      "Network Card: 25G Optical Port*2",
    ],
  },
  {
    packName: "INTEL XEON P8136*2",
    price: "4607",
    packageFor: "Suitable for large-scale database processing and online transaction processing",
    icon: "40",
    preFeatures: [
      "Memory: 512GB",
      "Storage: 7.68TB U.2 SSD*2 + 960GB U.2 SSD*2",
      "GPU: 8*NVIDIA RTX 4090",
      "Network Card: 10G*1 + 200G*1",
    ],
  },
  {
    packName: "AMD EPYC 75F3*2",
    price: "4607",
    packageFor: "Suitable for scientific computing and data analysis",
    icon: "40",
    preFeatures: [
      "Memory: 512GB",
      "Storage: 1.92TB M.2*1 + 7.68TB U.2 SSD*2",
      "GPU: 8*NVIDIA RTX 4090",
      "Network Card: 10G*2 + 40G2/100G*2",
    ],
  },
];

const PricingMonthly = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((val, i) => (
        <div className="col-lg-4 col-md-6" key={i}>
          <div className="pr-table-wrapper">
            <div className="pack-name">{val.packName}</div>
            <div className="price font-rubik">
              ${val.price}.<sup>99</sup>
            </div>
            <div className="pack-rec font-rubik">{val.packageFor}</div>
            <img
              src={`images/icon/${val.icon}.svg`}
              alt="icon"
              className="icon"
            />
            <div className="bill-cycle">Billed per agent</div>
            <ul className="pr-feature">
              {val.preFeatures.map((list, i) => (
                <li key={i}>{list}</li>
              ))}
            </ul>
            <a href="#" className="theme-btn-four">
              Choose Plan
            </a>
            <div className="trial-text font-rubik">
              Experience unparalleled performance from today
            </div>
          </div>
          {/*  /.pr-table-wrapper */}
        </div>
      ))}
    </div>
  );
};

export default PricingMonthly;
