import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../components/header/HeaderTwo";
import Social from "../../../components/social/Social";
import FooterTwo from "../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../components/footer/CopyRightTwo";
import AboutFour from "../../../components/about/AboutFour";
import CallToActionThree from "../../../components/call-to-action/CallToActionThree";

const ProductCustomerSupport = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>
          Affiliate || Lightlayer - Worldwide ISP
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderTwo />
      {/* End Header */}

      {/* 	=============================================
				Fancy Hero Three
			============================================== */}
      <div className="fancy-hero-three bg-transparent">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="shapes shape-five"></div>
        <div className="shapes shape-six"></div>
        <div className="bg-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-9 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Affiliate Program</h1>
                <p className="sub-heading">
                  Join us and enjoy a 15% rebate
                </p>
                <Link
                  to="/pricing-cs"
                  className="theme-btn-two trial-button mt-50 md-mt-30"
                >
                  {" "}
                  Register NOW
                </Link>
              </div>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper  */}
      </div>
      {/* /.fancy-hero-three */}

      {/* 	=============================================
				Fancy Feature Seven
			==============================================  */}
      <div className="fancy-feature-seven">
        <div className="container">
          <div className="title-style-two text-center mb-150 md-mb-70">
            <p>Affiliate Program</p>
            <h2>
              We welcome anyone to join Lightlayer's Affiliate Program with a
              <span> 
                -15% commission {" "}
                <img src="images/shape/line-shape-8.svg" alt="line shape" />
              </span>{" "}
              rebate.
            </h2>
          </div>
          {/* /.title-style-two */}

          <div className="block-wrapper">
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_04.svg" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>How to get start</h6>
                    <h3 className="title font-gilroy-bold">
                      You need register as a Lightlayer user.
                    </h3>
                    <p className="font-rubik">
                      1. Activate the Commission Promotion Affiliate.<br />
2. Place your unique link on your personal website or promotion channel.<br />
3. When a user places an order and pays through your link, you will receive a rebate.<br />
4. Withdrawals can be made once the amount reaches 100 USD.

                    </p>
                  </div>
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_05.svg" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Requirements</h6>
                    <h3 className="title font-gilroy-bold">
                     What are the requirements to register as a promotional user?
                    </h3>
                    <p className="font-rubik">
                      Anyone is welcome to register and become our promotional member. Of course, if you have your own review website, your promotion efforts can be more effective.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_06.svg" alt="illustrator" />
                  </div>{" "}
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>What should you do?</h6>
                    <h3 className="title font-gilroy-bold">
                      How to get a commission?
                    </h3>
                    <p className="font-rubik">
                      Once a user registers, places an order through your link, and uses it for a month, you will get your commission. You will get the every month commission as long as the order is renewed.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>{" "}
            {/* /.block-style-nine */}
            <div className="block-style-nine">
              <div className="row align-items-center">
                <div
                  className="col-lg-7 col-md-9 m-auto order-lg-last"
                  data-aos="fade-left"
                  data-aos-duration="1200"
                >
                  <div className="illustration-holder">
                    <img src="images/assets/ils_07.svg" alt="illustrator" />
                  </div>
                  {/* /.illustration-holder */}
                </div>
                <div
                  className="col-lg-5 order-lg-first"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  <div className="text-wrapper">
                    <h6>Withdraw</h6>
                    <h3 className="title font-gilroy-bold">
                      How to withdraw my earnings?
                    </h3>
                    <p className="font-rubik">
                      When the commission reaches 100 USD, you can request a withdrawal to your account balance or personal bank account. If you choose to withdraw to the account balance, you can use it to pay your account's renewal bills. For withdrawals to a personal bank account, we support PayPal and bank support overseas transfers, and it may charge handling fees.
                    </p>
                  </div>{" "}
                  {/* /.text-wrapper */}
                </div>
              </div>
            </div>
            {/* /.block-style-nine */}
          </div>
        </div>
      </div>
      {/* /.fancy-feature-seven */}

      {/* =====================================================
				Fancy Text Block Thirteen
			===================================================== */}
      <div className="fancy-text-block-thirteen pt-140 md-pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div
              className="col-lg-6 order-lg-last"
              data-aos="fade-left"
              data-aos-duration="1200"
            >
              <div className="text-wrapper">
                <div className="title-style-two">
                  <h2>What counts as a valid referral?</h2>
                </div>
                {/* /.title-style-two */}
                <p>
                  A valid referral must be a natural person referring to another natural person, and the order must be used for at least 1 month. If self-referral is detected, we reserve the right to clear all your promotional records.
                </p>
              </div>
            </div>
            {/* End .col */}
            <div
              className="col-lg-6 col-md-8 m-auto order-lg-first"
              data-aos="fade-right"
              data-aos-duration="1200"
            >
              <img src="images/assets/feature-img-11.png" alt="feature" />
            </div>
            {/* End .col */}
          </div>
        </div>
      </div>
      {/* /.fancy-text-block-thirteen */}

      {/*=====================================================
				Useable Tools
			===================================================== */}
      <div className="useable-tools-section-two bg-shape mb-250 mt-150 md-mt-100 md-mb-100">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <div className="container">
            <div className="title-style-two text-center mb-70 md-mb-10">
              <div className="row">
                <div className="col-lg-10 col-md-11 m-auto">
                  <p>Integrates with your tools</p>
                  <h2>
                    Connect deski with the software you
                    <span>
                      use every
                      <img src="images/shape/line-shape-2.svg" alt="" />
                    </span>
                    day.
                  </h2>
                </div>
              </div>
            </div>
            {/*  /.title-style-two */}

            <div className="icon-wrapper">
              <Social />
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.useable-tools-section-two */}

      {/* 	=====================================================
				Fancy Text Block Fourteen
			===================================================== */}
      <div className="fancy-text-block-fourteen pt-30">
        <div className="container">
          <AboutFour />
        </div>
      </div>
      {/* /.fancy-text-block-fourteen */}

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-225 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default ProductCustomerSupport;
