import React from "react";

const About = () => {
  return (
    <div className="row align-items-center">
      <div className="col-lg-6" data-aos="fade-right" data-aos-duration="1200">
        <img src="images/media/img_57.png" alt="about" className="m-auto" />
      </div>
      {/* End .col */}

      <div className="col-lg-6" data-aos="fade-left" data-aos-duration="1200">
        <div className="text-wrapper">
          <div className="client-info font-rubik">
            Over <span>Sold over 15000+ host</span>
          </div>
          <div className="title-style-five">
            <h2>
              <span>Your Best</span> choice.
            </h2>
          </div>
          <p className="font-rubik">
            The light cloud is flexible, and cost-controllable. You can upgrade it on demand, 
          </p>
          <p className="font-rubik">
            and you can control your host through the control platform at any time.
          </p>
          <div className="name font-slab">Lightlayer, light cloud host first choice.</div>
        </div>
        {/* /.text-wrapper */}
      </div>
      {/* End .col */}
    </div>
  );
};

export default About;
