import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import BrandSeven from "../../../components/brand/BrandSeven";
import ProjectInfo2 from "./ProjectInfo2";

const PortfolioV5 = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          LA Data Data Center || Lightlayer - Light Cloud Easy Computing
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
         Fancy Hero Six
        ==============================================  */}
      <div className="portfolio-details-one mt-75 mb-150 md-mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <div className="header text-center">
                <div className="tag">DATA CENTER INTRO</div>
                <div className="title-style-ten">
                  <h2>Taiwan - CHIEF TELECOM DATA CENTER</h2>
                </div>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div className="main-content mt-75">
            <img
              src="images/gallery/img_33.jpg"
              alt="gallery"
              className="mb-90 md-mb-50"
            />
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="row">
                  <div className="col-md-4 order-md-last">
                    <ul className="project-info clearfix ps-xl-5">
                      <ProjectInfo2 />
                    </ul>
                    {/* End .project info */}
                  </div>
                  {/* End .col */}

                  <div className="col-md-8 order-md-first">
                    <h4>Overview</h4>
                    <p>
Chief is a professional telecommunication service provider. As an IDC/Tele-Center service provider, Chief aim to maximize its partners’ facility satisfaction and provides a 99.999% high availability and stability structure. In addition to the hardware facility, Chief has Type I telecommunication carriers’ circuit and Type II value-added network operators’ traffics under a comprehensive management system, professional facility management staff, flexible policies, and convenient location are add-on values to our customers.
                    </p>
                    <h4>High Stability</h4>
                    <p>
Chief’s Internet data center with 99.999 reliability is immaculate with redundant UPS backed by diesel generators, continuous air-conditioning, HVAC temperature control system and VESDA fire detection to meet the most stringent and mission-critical hosting requirements
                    </p>
                    <a
                      href="https://account.lightlayer.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="theme-btn-eight"
                    >
                      Order Now
                    </a>
                  </div>
                  {/* End .col */}
                </div>
                {/* End .row */}

                <div className="top-border mt-70 pt-50 md-mt-40">
                  <ul className="portfolio-pagination d-flex justify-content-between">
                    <li>
                      <a href="http://www.lightlayer.net/portfolio-details-v1losangeles" className="d-flex align-items-center">
                        <img
                          src="images/gallery/img_34.jpg"
                          alt="gallery"
                          className="d-none d-lg-block"
                        />
                        <span className="d-inline-block ps-lg-4">
                          <span className="tp1 d-block">Previous</span>
                          <span className="tp2 d-block">LOS ANGELES</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://www.lightlayer.net/portfolio-details-v3manila" className="d-flex flex-row-reverse align-items-center text-end"
                      >
                        <img
                          src="images/gallery/img_35.jpg"
                          alt="gallery"
                          className="d-none d-lg-block"
                        />
                        <span className="d-inline-block pe-lg-4">
                          <span className="tp1 d-block">Next</span>
                          <span className="tp2 d-block">MANILA</span>
                        </span>
                      </a>
                    </li>
                  </ul>

                  {/* End .portfolio-pagination */}
                </div>
                {/* End .top-border */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-hero-six */}

      {/* =====================================================
            Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven dark-bg pt-120 md-pt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default PortfolioV5;
