import React from "react";
import { Link } from "react-router-dom";

const linksFooterContent = [
  {
    itemName: "Home",
    routePath: "/",
  },
  {
    itemName: "Reseller",
    routePath: "/service-v3",
  },
  {
    itemName: "Affiliate",
    routePath: "/product-customer-support",
  },
  {
    itemName: "About us",
    routePath: "/about-cs",
  },
  {
    itemName: "News",
    routePath: "/blog-v2",
  },
];

const legalFooterContent = [
  {
    itemName: "Terms of Service",
    routePath: "/terms-conditions",
  },
  {
    itemName: "Privacy Policy",
    routePath: "/terms-conditions",
  },
];

const productsFooterContent = [
  {
    itemName: "Data Center",
    routePath: "/gallery-slider",
  },
  {
    itemName: "Light Cloud",
    routePath: "/pricing-eo",
  },
  {
    itemName: "Server",
    routePath: "/pricing-cs",
  },
  {
    itemName: "Anti-DDoS",
    routePath: "/service-v4",
  },
];

const FooterFour = () => {
  return (
    <div className="row justify-content-end">
      <div className="col-xl-4 col-lg-3 col-12 footer-about-widget">
        <div className="logo">
          <a href="index-event.html">
            <img src="images/logo/ll_05.png" alt="" />
          </a>
        </div>
        <ul className="font-rubik mt-10">
          <li>
            <a href="mailto:sales@lightlayer.net">sales@lightlayer.net</a>
          </li>
          <li>
            <a href="https://t.me/Lightlayerbot">Telegram @lightlayer</a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Links</h5>
        <ul>
          {linksFooterContent.map((list, i) => (
            <li key={i}>
              <Link to={list.routePath}>{list.itemName}</Link>
            </li>
          ))}
        </ul>
      </div>

      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Legal</h5>
        <ul>
          {legalFooterContent.map((list, i) => (
            <li key={i}>
              <Link to={list.routePath}>{list.itemName}</Link>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}

      <div className="col-xl-2 col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title font-slab">Products</h5>
        <ul className="pe-5">
          {productsFooterContent.map((list, i) => (
            <li key={i}>
              <Link to={list.routePath}>{list.itemName}</Link>
            </li>
          ))}
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
  );
};

export default FooterFour;
