import React from "react";

const PricingContent = [
  {
    title: "Startup",
    price: "4",
    priceMeta: "per month",
    packageName: "LA-VG01",
    skewClass: "skew-right",
    priceList: [
      {
        list: "1 Core",
        listClass: "",
      },
      {
        list: "1G RAM",
        listClass: "",
      },
      {
        list: "1 IPv4",
        listClass: "",
      },
      {
        list: "100M Speed port",
        listClass: "",
      },
      {
        list: "Unlimited data",
        listClass: "",
      },
      {
        list: "NOT Upgradeable",
        listClass: "disable",
      },
    ],
    animatinDelay: "0",
  },
  {
    title: "Pay As You Go",
    price: "5.6",
    priceMeta: "Start from/per month",
    packageName: "LA-VG02",
    skewClass: "skew-left",
    priceList: [
      {
        list: "Start from 2 Cores",
        listClass: "",
      },
      {
        list: "Start from 2G RAM",
        listClass: "",
      },
      {
        list: "Start from 1 IPv4",
        listClass: "",
      },
      {
        list: "Start from 200M Speed port",
        listClass: "",
      },
      {
        list: "Unlimited data",
        listClass: "",
      },
      {
        list: "Upgradeable",
        listClass: "",
      },
    ],
    animatinDelay: "100",
  },
  {
    title: "Business",
    price: "53.60",
    priceMeta: "per month",
    packageName: "LA-VG06",
    skewClass: "skew-right",
    priceList: [
      {
        list: "32 CORE",
        listClass: "",
      },
      {
        list: "32G RAM",
        listClass: "",
      },
      {
        list: "1 IPv4",
        listClass: "",
      },
      {
        list: "200M Speed port",
        listClass: "",
      },
      {
        list: "Unlimited data",
        listClass: "",
      },
      {
        list: "Upgradeable",
        listClass: "",
      },
    ],
    animatinDelay: "200",
  },
];

const PricingThree = () => {
  return (
    <div className="row justify-content-center">
      {PricingContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={item.animatinDelay}
        >
          <div className={`pr-table-wrapper ${item.skewClass}`}>
            <div className={`pack-name font-slab ${item.packageName}`}>
              <span>{item.title}</span>
            </div>
            <div className="price font-slab">${item.price}</div>
            <p className="user-condition">{item.priceMeta}</p>
            <img src="images/shape/114.svg" alt="shape" className="line" />
            <ul>
              {item.priceList.map((val, i) => (
                <li className={val.listClass} key={i}>
                  {val.list}
                </li>
              ))}
            </ul>
            <a href="https://account.lightlayer.net/" className="subscribe-btn font-rubik">
              Subscribe Now
            </a>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PricingThree;
