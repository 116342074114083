import React from "react";
import { Link } from "react-router-dom";
import HeaderFive from "../../../components/header/HeaderFive";
import { Helmet } from "react-helmet";
import FooterEight from "../../../components/footer/FooterEight";
import CopyRightFour from "../../../components/footer/CopyRightFour";
import CounterOne from "../../../components/counter/CounterOne";
import FancyFeatureTwentyEightV2 from "../../../components/features/FancyFeatureTwentyEightV2";
import Service from "../../../components/service/Service";

const ServiceV4 = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Anti-DDoS || Ligthlayer- Light Cloud Easy Computing</title>
      </Helmet>
      {/* End Page SEO Content */}

      <HeaderFive />
      {/* End Header */}

      {/*  =====================================================
           	Fancy Hero Seven
        ===================================================== */}
      <div
        className="fancy-hero-seven"
        style={{
          backgroundImage: `url(${
            process.env.PUBLIC_URL + "images/media/img_109.png"
          })`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-8 col-lg-10 m-auto">
              <h2 className="font-gordita">Anti-DDoS</h2>
              <p className="font-gordita">
                Ensure the availability of network services and protect the network from the impact of large-scale malicious traffic.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /.fancy-hero-seven --> */}

      {/* 	=============================================
				Fancy Text block Seven
			==============================================  */}
      <div className="fancy-text-block-seven seven mt-130 md-mt-80">
        <div className="bg-wrapper no-bg">
          <div className="container">
            <div className="row align-items-center">
              <div
                className="col-lg-5 col-md-6 col-sm-10 m-auto"
                data-aos="fade-right"
                data-aos-duration="1200"
              >
                <CounterOne />
              </div>
              {/* End .col */}

              <div
                className="col-xl-6 col-lg-7 ms-auto"
                data-aos="fade-left"
                data-aos-duration="1200"
              >
                <div className="quote-wrapper pt-60">
                  <img src="images/icon/185.png" alt="icon" className="icon" />
                  <blockquote className="font-rubik">
                    Anti-DDoS services, including DDoS high-protection services, employ layered defense and distributed cleaning. Through sophisticated multi-layer filtering and defense technologies, they can effectively detect and filter attack traffic.
                  </blockquote>
                  <h6 className="mt-20">
                    Anti-DDoS <span>Anti-Distributed Denial of Service</span>
                  </h6>
                </div>
                {/* <!-- /.quote-wrapper --> */}
              </div>
              {/* End .col */}
            </div>
            {/* End .row */}

            <div className="mt-90 pb-80 bottom-border">
              <div className="row">
                <div className="col-xl-11 m-auto">
                  <FancyFeatureTwentyEightV2 />
                </div>
              </div>
            </div>
          </div>
          {/* End .container */}
        </div>
        {/* <!-- /.bg-wrapper --> */}
      </div>
      {/* <!-- /.fancy-text-block-seven --> */}


      {/*  =====================================================
            Footer Style Eight
        ===================================================== */}
      <footer className="theme-footer-eight mt-100">
        <div className="top-footer">
          <div className="container">
            <FooterEight />
          </div>
          {/* /.container */}
        </div>

        <div className="container">
          <div className="bottom-footer mt-50 md-mt-30">
            <CopyRightFour />
          </div>
        </div>
      </footer>
      {/* /.theme-footer-eight  */}
    </div>
  );
};

export default ServiceV4;
