import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import BlogDetailsForm from "../../../components/form/BlogDetailsForm";

const BlogDetails = () => {
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Company News || Lightlayer - Light Cloud Easy Computing</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      <div className="fancy-hero-five bg-white">
        <img
          src="images/shape/95.svg"
          alt="shape"
          className="shapes shape-one"
        />
        <img
          src="images/shape/96.svg"
          alt="shape"
          className="shapes shape-two"
        />
        <div className="bg-wrapper ">
          <div className="container">
            <div className="col-lg-10 m-auto text-center">
              <h6 className="page-title">Our News</h6>
              <h1 className="heading">
                <span>
                  Los Angeles 
                  <img src="images/shape/line-shape-11.svg" alt="" />
                </span>{" "}
                Datacenter
              </h1>
            </div>
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">
            <div className="col-xl-10 offset-xl-1 col-lg-12 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                <img
                  src="images/blog/media_28.png"
                  alt="media post"
                  className="image-meta"
                />
                <div className="tag">02 Feb. 2024</div>
                <h3 className="title">
                  Unparalleled Connectivity and Performance
                </h3>
                <p>
                  Lightlayer's Los Angeles datacenter offers a comprehensive suite of cloud and dedicated server solutions designed to meet the diverse needs of businesses and individuals worldwide. With its strategic location and state-of-the-art infrastructure, Lightlayer provides customers with unparalleled connectivity, exceptional performance, and unwavering reliability.
                </p>
                <p>
                 Los Angeles - A Global Internet Hub<br />

Los Angeles is a major international internet hub, serving as a landing point for numerous submarine cables that connect the Americas, Asia, and Oceania. This strategic location ensures that Lightlayer's Los Angeles datacenter delivers low-latency connectivity to users across the globe. Whether you're accessing your data from North America, Europe, or the Asia-Pacific region, you can expect fast and reliable performance.

                </p>
                <blockquote className="blog-quote">
                  Robust Infrastructure and Uncompromising Reliability<br />
                  Lightlayer's Los Angeles datacenter is built on a foundation of cutting-edge technology and robust infrastructure. The facility is equipped with the latest generation of servers, networking equipment, and security systems, ensuring maximum uptime and optimal performance for all customer applications and services. Lightlayer's commitment to reliability is reflected in its meticulous attention to detail, redundant systems, and proactive monitoring, ensuring that your business operations run smoothly and seamlessly.
                </blockquote>
                <p>
                  Lightweight Cloud Servers for Scalability and Cost-Effectiveness<br />
                  Lightlayer's Los Angeles datacenter offers a range of lightweight cloud server options, starting from 1 core and 1 GB of memory. These servers are ideal for individuals and small businesses seeking a cost-effective and scalable cloud hosting solution. With the ability to elastically upgrade CPU cores, memory, storage, and bandwidth, you can easily adjust your server resources to meet changing demands, ensuring optimal performance and cost efficiency.
                </p>
                <img
                  src="images/blog/media_41.png"
                  alt="media post"
                  className="image-meta mt-35"
                />
                <div className="mark-text">
                  High-Configuration Physical Servers for Demanding Applications
                </div>
                <p>
                  For enterprises and applications that require dedicated resources and exceptional performance, Lightlayer's Los Angeles datacenter provides a selection of high-configuration physical servers. These servers feature powerful ARM processors, starting from 44 cores and scaling up to an impressive 128 cores. Combined with M2 solid-state drives (SSDs) for lightning-fast storage, these servers can handle even the most demanding workloads with ease.
                </p>
                <h4>Intuitive Control Panel for Seamless Management.</h4>
                <p>
                  Lightlayer's user-friendly control panel empowers you with complete control over your cloud and dedicated servers. From the convenience of your web browser, you can effortlessly perform a wide range of tasks, including provisioning new servers, elastically upgrading resources, managing your billing, and monitoring network usage. The control panel's intuitive interface makes it easy to manage your infrastructure, even for non-technical users.
                </p>
                <p>
                 Lightlayer: Your Trusted Partner for Global Connectivity and Performance<br />
                 Lightlayer's Los Angeles datacenter is the ideal choice for businesses and individuals seeking reliable, high-performance cloud and dedicated server solutions. With its strategic location, robust infrastructure, and comprehensive product portfolio, Lightlayer empowers customers to succeed in the digital age.
                </p>
                <div className="d-sm-flex align-items-center justify-content-between share-area">
                  <ul className="tag-feature d-flex">
                    <li>Tag: &nbsp;</li>
                    <li>
                      <a href="#">datacenter,</a>
                    </li>
                    <li>
                      <a href="#">marketing,</a>
                    </li>
                    <li>
                      <a href="#">products</a>
                    </li>
                  </ul>
                  <ul className="share-option d-flex align-items-center">
                    <li>Share</li>
                    <li>
                      <a href="#" style={{ background: " #F6616F" }}>
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" style={{ background: " #41CFED" }}>
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                      </a>
                    </li>
                    <li>
                      <a href="#" style={{ background: " #588DE7" }}>
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /End post content  */}

              <div className="comment-area">
                <h3 className="title">2 Comments</h3>
                <div className="single-comment">
                  <div className="d-flex">
                    <img
                      src="images/blog/media_30.png"
                      alt="blog post"
                      className="user-img"
                    />
                    <div className="comment">
                      <h6 className="name">Mia Green</h6>
                      <div className="time">04 Feb, 2024, 7:30pm</div>
                      <p>
                        As a partner for many years, it is an honor to witness the launch of the Los Angeles datacenter.{" "}
                      </p>
                      <button className="reply">Reply</button>
                    </div>
                    {/* /.comment */}
                  </div>
                </div>
                {/* /.single-comment */}
                <div className="single-comment">
                  <div className="d-flex">
                    <img
                      src="images/blog/media_31.png"
                      alt="blog post"
                      className="user-img"
                    />
                    <div className="comment">
                      <h6 className="name">Yuran</h6>
                      <div className="time">05 Feb, 2024, 2:13pm</div>
                      <p>
                        I can’t wait to recommend Lightlayer products to everyone!{" "}
                      </p>
                      <button className="reply">Reply</button>
                    </div>
                    {/*  /.comment */}
                  </div>
                </div>
                {/* /.single-comment */}
              </div>
              {/* /.comment-area */}

              <div className="comment-form-section m0">
                <h3 className="title">Leave A Comment</h3>
                <p>
                  <Link to="/login">Sign in</Link> to post your comment or
                  singup if you dont have any account.
                </p>
                <div className="form-style-light">
                  <BlogDetailsForm />
                </div>
                {/* /.form-style-light */}
              </div>
              {/* /.comment-form-section */}
            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-one pt-130 md-pt-70">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogDetails;
