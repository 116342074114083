import React from "react";
import { Link } from "react-router-dom";

const FeatureContent = [
  {
    icon: "20",
    meta: "Centralized Management",
    subTitle: `SD-WAN provides a centralized management platform, allowing enterprises to configure, monitor, and manage the entire network through a single interface. This simplifies network management and improves efficiency.`,
    dataDelay: "0",
  },
  {
    icon: "21",
    meta: "Dynamic Path Selection",
    subTitle: `SD-WAN can dynamically select the best path based on real-time network conditions, such as latency, packet loss, and bandwidth utilization. This helps optimize application performance and ensures high-quality transmission for critical business applications.`,
    dataDelay: "100",
  },
  {
    icon: "22",
    meta: "Enhanced Security",
    subTitle: `SD-WAN integrates multiple security features, including encryption, Virtual Private Network (VPN), firewalls, and Intrusion Detection/Prevention Systems (IDS/IPS). This enhances the security of data transmission and protects enterprise networks from attacks.`,
    dataDelay: "0",
  },
  {
    icon: "21",
    meta: "Cost Efficiency",
    subTitle: `By leveraging public internet connections (e.g., broadband, 4G/5G) alongside traditional private lines (e.g., MPLS), SD-WAN can significantly reduce WAN operational costs while improving network performance.`,
    dataDelay: "100",
  },
  {
    icon: "22",
    meta: "Application Awareness",
    subTitle: `SD-WAN can identify and differentiate between various types of application traffic, prioritizing critical business applications. This ensures the performance and reliability of key applications while reducing the impact of non-critical applications on network resources.`,
    dataDelay: "0",
  },
  {
    icon: "20",
    meta: "Flexibility and Scalability",
    subTitle: `SD-WAN allows enterprises to flexibly add or reduce network resources as needed, quickly responding to business changes. Additionally, SD-WAN supports connectivity across multi-cloud environments and multiple sites, accommodating modern enterprise distributed network architectures.`,
    dataDelay: "100",
  },
];

const FeatureSixV2 = () => {
  return (
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <Link to="/service-details" className="block-style-five">
            <div className="icon">
              <img src={`images/icon/${val.icon}.svg`} alt="icon" />
            </div>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p>{val.subTitle}</p>
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FeatureSixV2;
