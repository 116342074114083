import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import HeaderTwo from "../../../../components/header/HeaderTwo";
import CallToActionThree from "../../../../components/call-to-action/CallToActionThree";
import FooterTwo from "../../../../components/footer/FooterTwo";
import CopyRightTwo from "../../../../components/footer/CopyRightTwo";
import CounterThreeColored from "../../../../components/counter/CounterThreeColored";
import FancyTextBlockTen from "../../../../components/fancy-text-block/FancyTextBlockTen";
import FancyFeatureSeven from "../../../../components/features/FancyFeatureSeven";
import TeamFour from "../../../../components/team/TeamFour";

const CustomerSupport = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          About Us || Lightlayer - Light Cloud Easy Computing
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      {/* =============================================
		   Start Header
		   ============================================== */}
      <HeaderTwo />
      {/* End HeaderTwo Variation Three */}

      {/* 	=============================================
				Fancy Hero Two
			==============================================  */}
      <div className="fancy-hero-two">
        <div className="bg-wrapper">
          <div className="container">
            <div className="page-title">About us</div>
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-10 m-auto">
                <h1 className="heading">Your Trusted Tier II Internet Service Provider</h1>
                <p className="sub-heading">
                  -Light Cloud Easy Computing-
                </p>
              </div>
            </div>
          </div>
          {/* End .container */}
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-hero-two */}

      {/* =============================================
				Fancy Text block Nine
			==============================================  */}
      <div className="fancy-text-block-nine mt-130 md-mt-80">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="shapes shape-four"></div>
        <div className="container">
          <div className="title-style-two text-center mb-35 md-mb-10">
            <div className="row">
              <div className="col-lg-10 m-auto">
                <p>Our Story</p>
                <h2>Global Infrastructure and Connectivity</h2>
              </div>
            </div>
          </div>
          {/* End title-style-two */}

          <div className="row">
            <div className="col-xl-10 m-auto">
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                Lightlayer is a leading Tier 2 telecommunications value-added service provider, offering a wide range of services to meet the evolving needs of businesses and individuals globally. With a strong focus on customer satisfaction and innovation, Lightlayer is committed to delivering exceptional services and solutions that empower its customers to succeed in the digital age.
              </p>
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                Lightlayer's infrastructure spans across key locations worldwide, including Los Angeles in the United States and Taiwan in China. The company is continuously expanding its global footprint, with plans to deploy in Japan, Korea, Singapore, and other strategic regions. By partnering with renowned international network operators, Lightlayer ensures the highest quality of network services, providing customers with seamless connectivity and optimal performance.
              </p>
              <p
                className="text-meta"
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay="100"
              >
                At Lightlayer, customer experience is paramount. The company provides comprehensive technical support 24/7/365, ensuring that customers receive prompt and expert assistance whenever they need it. Whether it's a minor query or a complex technical issue, Lightlayer's dedicated support team is always ready to go the extra mile to resolve any challenges customers may face.
              </p>
            </div>
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.fancy-text-block-nine */}

      {/* =====================================================
				Counter With Icon One
			===================================================== */}
      <div className="counter-with-icon-one border-style pt-120 pb-45 md-pt-70">
        <div className="container">
          <div className="border-style">
            <CounterThreeColored />
          </div>
        </div>
        {/* End .container */}
      </div>
      {/*  /.counter-with-icon-one */}

      {/* 	=============================================
				Fancy Text block Ten
			==============================================  */}
      <div className="fancy-text-block-ten mt-120 md-mt-60">
        <div className="container">
          <FancyTextBlockTen />
        </div>
      </div>
      {/* /.fancy-text-block-ten */}

      {/* =====================================================
				Fancy Feature Five
			===================================================== */}
      <div className="fancy-feature-five mt-200 md-mt-100">
        <div className="bg-wrapper">
          <div className="shapes shape-one"></div>
          <div className="shapes shape-two"></div>
          <div className="shapes shape-three"></div>
          <div className="shapes shape-four"></div>
          <img
            src="images/shape/47.svg"
            alt="shape"
            className="shapes shape-five"
          />
          <div className="container">
            <div className="title-style-two text-center mb-60 md-mb-40">
              <div className="row">
                <div className="col-xl-8 col-lg-9 m-auto">
                  <p>Our Values</p>
                  <h2>We’r driven by our values</h2>
                  <div className="sub-text">
                    Lightlayer is committed to providing its customers with exceptional services, unwavering support, and innovative solutions that empower them to succeed in the ever-changing digital landscape.
                  </div>
                </div>
              </div>
            </div>
            {/* End .title-style-two */}
            <div className="row justify-content-center">
              <FancyFeatureSeven />
            </div>
            {/* End .row */}
          </div>
        </div>
        {/* /.bg-wrapper */}
      </div>
      {/* /.fancy-feature-five */}

      

      {/* 	=====================================================
			Fancy Short Banner Three
			===================================================== */}
      <div className="fancy-short-banner-three mt-225 md-mt-150">
        <div className="container">
          <div className="bg-wrapper">
            <CallToActionThree />
          </div>
          {/* /.bg-wrapper */}
        </div>
        {/* /.container */}
      </div>
      {/* /.fancy-short-banner-four */}

      {/* 	=====================================================
				Footer Style Two
			===================================================== */}
      <footer className="theme-footer-two pt-150 md-pt-80">
        <div className="top-footer">
          <div className="container">
            <FooterTwo />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRightTwo />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default CustomerSupport;
