import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import FooterSeven from "../../../components/footer/FooterSeven";
import CopyRightThree from "../../../components/footer/CopyRightThree";
import BrandSeven from "../../../components/brand/BrandSeven";
import ProjectInfo3 from "./ProjectInfo3";

const PortfolioV5 = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          LA Data Data Center || Lightlayer - Light Cloud Easy Computing
        </title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
         Fancy Hero Six
        ==============================================  */}
      <div className="portfolio-details-one mt-75 mb-150 md-mb-100">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <div className="header text-center">
                <div className="tag">DATA CENTER INTRO</div>
                <div className="title-style-ten">
                  <h2>Manila - Vitro Makati Data Center 2</h2>
                </div>
              </div>
            </div>
          </div>
          {/* End .row */}

          <div className="main-content mt-75">
            <img
              src="images/gallery/img_33.jpg"
              alt="gallery"
              className="mb-90 md-mb-50"
            />
            <div className="row">
              <div className="col-xl-11 m-auto">
                <div className="row">
                  <div className="col-md-4 order-md-last">
                    <ul className="project-info clearfix ps-xl-5">
                      <ProjectInfo3 />
                    </ul>
                    {/* End .project info */}
                  </div>
                  {/* End .col */}

                  <div className="col-md-8 order-md-first">
                    <h4>Overview</h4>
                    <p>
                      The data center operated by ePLDT. We do not yet have the details of what services are available at Vitro Makati Data Center 2 - VM2. If you need to know if Vitro Makati Data Center 2 - VM2 is carrier-neutral, or if you need services such as remote hands or rack-mounted cages, we recommend you contact the data center's staff directly.
                    </p>
                    <h4>High Stability</h4>
                    <p>
                      Highest speed seen was 781.56mbps. Out of a total of 8,651 tests, the average corporate speed was 71.27mbps,
                    </p>
                    <a
                      href="https://account.lightlayer.net"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="theme-btn-eight"
                    >
                      Order Now
                    </a>
                  </div>
                  {/* End .col */}
                </div>
                {/* End .row */}

                <div className="top-border mt-70 pt-50 md-mt-40">
                  <ul className="portfolio-pagination d-flex justify-content-between">
                    <li>
                      <a href="http://www.lightlayer.net/portfolio-details-v2taiwan" className="d-flex align-items-center">
                        <img
                          src="images/gallery/img_34.jpg"
                          alt="gallery"
                          className="d-none d-lg-block"
                        />
                        <span className="d-inline-block ps-lg-4">
                          <span className="tp1 d-block">Previous</span>
                          <span className="tp2 d-block">TAIWAN</span>
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="http://www.lightlayer.net/portfolio-details-v1losangeles" className="d-flex flex-row-reverse align-items-center text-end"
                      >
                        <img
                          src="images/gallery/img_35.jpg"
                          alt="gallery"
                          className="d-none d-lg-block"
                        />
                        <span className="d-inline-block pe-lg-4">
                          <span className="tp1 d-block">Next</span>
                          <span className="tp2 d-block">LOS ANGELES</span>
                        </span>
                      </a>
                    </li>
                  </ul>

                  {/* End .portfolio-pagination */}
                </div>
                {/* End .top-border */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /.fancy-hero-six */}

      {/* =====================================================
            Footer Style Seven
        ===================================================== */}
      <footer className="theme-footer-seven dark-bg pt-120 md-pt-100">
        <div className="lg-container">
          <div className="container inner-btn-black">
            <FooterSeven />
          </div>

          <div className="container">
            <div className="bottom-footer">
              <CopyRightThree />
            </div>
          </div>
        </div>
        {/* /.lg-container */}
      </footer>
      {/* /.theme-footer-seven */}
    </div>
  );
};

export default PortfolioV5;
