import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CounterFive from "../../../components/counter/CounterFive";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import FancyFeatureThirtyFour from "../../../components/features/FancyFeatureThirtyFour";
import FancyVideoSix from "../../../components/video/FancyVideoSix";

const ServiceV2 = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>Anti-DDoS || Ligthlayer- Light Cloud Easy Computing</title>
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero One
        ==============================================  */}
      <div className="fancy-hero-one">
        <div className="container">
          <div className="row">
            <div className="col-xl-10 m-auto">
              <h2 className="font-rubik">NVIDIA GeForce RTX 4090</h2>
            </div>
            <div className="col-xl-9 m-auto">
              <p className="font-rubik">
                 When utilized as part of an AI inference platform, offers several distinctive characteristics that enhance its performance and usability for AI applications.
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* <!-- /.fancy-hero-one --> */}

      {/* =============================================
        Fancy Feature Thirty One
      ==============================================  */}
      <div className="fancy-feature-thirtyOne pt-120 pb-160 md-pt-80 md-pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 m-auto">
              <div className="title-style-four text-center pb-50 md-pb-20">
                <h3>Power your next big idea with our Nvidia 4090 GPU server.</h3>
                <p className="sub-text pt-20">
                  Elevate your business to new heights with our cutting-edge Nvidia 4090 graphics server. Experience unparalleled performance for your simulations, data analysis, and AI applications.
                </p>
              </div>
            </div>
          </div>
          {/* End .row */}
          <FancyFeatureThirtyFour />
        </div>
      </div>
      {/* <!-- /.fancy-feature-thirtyOne --> */}

      {/* 
      =============================================
            Footer
        ============================================== */}
      <div className="footer-bg-wrapper">
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="fancy-short-banner-two">
          <div className="container">
            <div className="content-wrapper">
              <div
                className="
                  bg-wrapper
                  d-lg-flex
                  align-items-center
                  justify-content-between
                "
              >
                <h2 className="font-gilroy-bold">
                  Are you interested now? Check the configuration.
                </h2>
                <Link to="/pricing-pm"> GO NOW</Link>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
              </div>
              {/* /.bg-wrapper */}
            </div>
            {/*  /.content-wrapper */}
          </div>
          {/* /.container */}
        </div>
        {/* /.fancy-short-banner-two */}

        <footer className="theme-footer-one pt-130 md-pt-70">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default ServiceV2;
