import React from "react";

const CopyRightTwo = () => {
  return (
    <div className="bottom-footer-content">
      <div className="d-flex align-items-center justify-content-center">
        <p>
          {" "}
          Copyright @{new Date().getFullYear()}{" "}
          <a
            href="www.ligthlayer.net"
            target="_blank"
            rel="noopener noreferrer"
          >
            Ligthlayer Technology
          </a>{" "}
          Co., Ltd.
        </p>
      </div>
    </div>
    //   /.bottom-footer
  );
};

export default CopyRightTwo;
