import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="row justify-content-end">
      <div
        className="col-lg-3 col-md-12 footer-about-widget"
        data-aos="fade-up"
        data-aos-duration="1200"
      >
        <a href="index.html" className="logo">
          <img src="images/logo/ll_02.png" alt="" />
        </a>
      </div>
      {/* /.about-widget */}
      <div
        className="col-lg-3 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="50"
      >
        <h5 className="footer-title">Links</h5>
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/service-v3">Reseller</Link>
          </li>
          <li>
            <Link to="/product-customer-support">Affiliate</Link>
          </li>
          <li>
            <Link to="/about-cs">About Us</Link>
          </li>
          <li>
            <Link to="/blog-v2">News</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 footer-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="100"
      >
        <h5 className="footer-title">Product</h5>
        <ul>
          <li>
            <Link to="/gallery-slider">Data Center</Link>
          </li>
          <li>
            <Link to="/pricing-eo">Light Cloud</Link>
          </li>
          <li>
            <Link to="/pricing-cs">Dedicated Server</Link>
          </li>
          <li>
            <Link to="/service-v4">Anti-DDoS</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div
        className="col-lg-3 col-md-4 address-list"
        data-aos="fade-up"
        data-aos-duration="1200"
        data-aos-delay="150"
      >
        <h5 className="footer-title">Contact us</h5>
        <ul>
          <li>
            <a href="#">Telegram @lightlayer</a>
          </li>
          <li>
            <a href="#" className="mobile-num">
              sales@lightlayer.net
            </a>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
    </div>
    //.row
  );
};

export default Footer;
